import { Helmet } from "react-helmet-async";
function Meta(props) {

  const follow = (props.follow === '1') ? '' : <meta name="robots" content="INDEX, FOLLOW" />;
  const title = props.title? props.title : 'Property4Sure | Buy/Rent Properties in India | Residential & Commercial'
  const desc = props.desc? props.desc : 'Property4Sure'
  const cano = props.cano? props.cano : ''
  return (
    <Helmet>
      {follow}
      <title>{title}</title>
			<link rel="canonical" href={'https://www.property4sure.com/'+cano} />
			<meta name="description" content={desc} />
    </Helmet>
  )
}
export default Meta